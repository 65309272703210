import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not Found" />
      <h1 className="page-title container">404: The Big Sad</h1>
      <p className="container">
        Unfortunately, it looks like this page does not exist.
      </p>
    </Layout>
  )
}

export default NotFoundPage
